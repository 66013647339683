<template>
  <main class="bg-white">
    <section class="core">
      <div class="title-box row-between">
        <div class="title bg-primary white lg row-center">审批流信息</div>
        <div class="tel warn nr">
          有疑问可联系我们：{{ config.purchase_phone }}
        </div>
      </div>
      <div class="block">
        <div class="logistics-box">
          <div class="info-logistics">
            <!-- 排序：
            <el-radio-group v-model="reverse">
              <el-radio :label="true">倒序</el-radio>
              <el-radio :label="false">正序</el-radio>
            </el-radio-group>  -->
            <el-timeline :reverse="reverse">
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.approval_date"
              >
                {{ activity.approver }} {{ activity.approval_content }}
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      reverse: false,
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  watch: {},
  methods: {
    /**
     * @desctiption 获取物流详情
     * @return void
     */
    async getOrderApprovalProcess() {
      let { code, data } = await this.$api.getOrderApprovalProcessApi({
        order_sn: this.order_sn,
      });
      if (code == 1) {
        this.activities = data.list;
        // console.log(this.activities);console.log(data);
      }
    },
  },
  created() {
    this.order_sn = this.$route.query.order_sn;
    this.getOrderApprovalProcess();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
main {
  overflow: hidden;
}

.title-box {
  padding-top: 37px;

  .title {
    width: 127px;
    height: 46px;
    background: #44aaea;
  }
}

.logistics-box {
  border: $-solid-border;
  padding: 35px 25px 30px;
  margin-bottom: 75px;

  .info-text {
    p {
      line-height: 2;

      em {
        color: $-color-normal;
      }

      span {
        margin-right: 10px;
      }
    }
  }

  .info-img {
    img {
      width: 64px;
      height: 64px;
      margin-right: 5px;
      margin-bottom: 5px;
      border: $-solid-border;
    }
  }

  .info-logistics {
    background: #f5f5f5;
    padding: 45px 40px;

    .logistics-list {
      &::after {
        content: "";
        width: 1px;
        top: 10px;
        bottom: 10px;
        background-color: #c8c8c8;
        position: absolute;
        left: 5px;
      }

      .logistics-item {
        align-items: flex-start;
        padding-left: 25px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;

          .item-detail {
            .detail-item {
              &:last-child {
                &::before {
                  background-color: #cf0101;
                }
              }
            }
          }
        }

        .item-time {
          margin-right: 25px;
          flex-shrink: 0;

          span {
            margin-right: 15px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .item-detail {
          color: #444;

          .detail-item {
            margin-bottom: 15px;
            line-height: 1.5;

            &::before {
              content: "";
              display: block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: #c4c4c4;
              position: absolute;
              left: 2px;
              transform: translateY(7px);
              z-index: 2;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.item-img {
  width: 64px;
  height: 64px;
}
</style>
